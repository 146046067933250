<template>
  <div class="promotion-song-manage">
      <div class="promotion-song-manage-header">
          <h2>ניהול שיר קידום</h2>
      </div>
      <div v-loading="is_pending" class="promotion-song-manage-content">
          <h1 v-if="!propmotion_song">לא קיים שיר קידום</h1>
          <table v-if="propmotion_song">
              <tr>
                  <th>שם השיר</th>
                  <th>כתובת URL</th>
                  <th>זמן מחזורי</th>
                  <th>פעולות</th>
              </tr>
              <tr>
                <td>{{propmotion_song.song_name}}</td>
                <td>{{propmotion_song.song_url}}</td>
                <td>{{propmotion_song.promotion_interval}}</td>
                <td>
                    <i @click="handle_delete" class="material-icons delete-icon icon">delete</i>
                    <i class="material-icons edit-icon icon">edit</i>
                    <i @click="handle_copy_to_clipboard(propmotion_song.song_url)" class="material-icons copy-icon icon">content_copy</i>
                </td>
              </tr>
          </table>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_promotion_song,delete_promotion_song} from './scripts/script'
import {slide_pop_successs,alert_confirm} from '../../../../Methods/Msgs'
import upload_image_storage from '../../../../Methods/use_general_storage'

export default {
    setup(){
        
        const is_pending = ref(false)

        const {delete_image_by_url} = upload_image_storage()

        const propmotion_song = ref(null)
        
        const handle_copy_to_clipboard = (url) =>{
            navigator.clipboard.writeText(url);
            slide_pop_successs('כתובת ה-URL הועתקה בהצלחה')
        }

        const handle_delete = ()=>{
            alert_confirm(`למחוק את השיר ${propmotion_song.value.song_name}?`)
            .then(async res=>{
                if(res.isConfirmed){
                    is_pending.value = true
                    if(propmotion_song.value.upload_type == 'file'){
                        await delete_image_by_url(propmotion_song.value.song_url)
                    }
                    await delete_promotion_song()

                    propmotion_song.value = null
                    is_pending.value = false
                }
            })
        }

        const init = async()=>{
            is_pending.value = true
            propmotion_song.value = await get_promotion_song()
            is_pending.value = false
        }

        init()
        return{
            propmotion_song,
            handle_copy_to_clipboard,
            handle_delete,
            is_pending,
        }
    }
}
</script>

<style scoped>
    .promotion-song-manage{
        width: 100%;
        height: 100%;
        color: #fff;
    }
    .promotion-song-manage-header{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .promotion-song-manage-content{
        width: 100%;
        height: calc(100% - 50px);
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
        max-width: 200px;
        overflow-x: auto;
        white-space: nowrap;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--main);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }

    .icon{
        cursor: pointer;
        font-size: 25px;
        margin: 0 5px;
    }
    .delete-icon {
        color: var(--danger);
    }
    .edit-icon {
        color: var(--warning);
    }
    .copy-icon{
        color: var(--blue);
    }
</style>