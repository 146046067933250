<template>
  <div class="create-new-song">
      <div v-loading="is_pending" class="create-new-song-wrapper slide-in-top">
          <div class="create-new-song-wrapper-header">
            <h2 v-if="is_dispatch">שיגור שיר קידום</h2>
            <h2 v-if="!song && !is_dispatch">הוספת שיר חדש</h2>
            <h2 v-if="song && !is_dispatch">עריכת שיר קיים</h2>
            <div v-if="song && !is_dispatch" @click="$emit('close')" class="exit-btn">
                <i class="material-icons">close</i>
            </div>
          </div>
          <div class="create-new-song-wrapper-content">
            <div class="create-new-song-wrapper-conten-field">
                <h2>שם השיר</h2>
                <el-input v-model="song_name" placeholder="שם השיר" />
            </div>
            <div class="create-new-song-wrapper-conten-field">
                <h2>אפשרות העלאת שיר</h2>
                <el-radio-group v-model="selected_upload_option">
                    <el-radio label="url">כתובת URL</el-radio>
                    <el-radio label="file">העלאת קובץ השמעה</el-radio>
                </el-radio-group>
            </div>
            <div v-if="selected_upload_option=='url'" class="create-new-song-wrapper-conten-field">
                <h2>כתובת URL של השיר</h2>
                <el-input v-model="song_url" @keypress.enter="handle_submit" placeholder="https://..." />
            </div>
            <div v-if="selected_upload_option=='file'" class="create-new-song-wrapper-conten-field">
                <h2>העלה קובץ</h2>
                <input type="file" @change="handle_change">
            </div>
            <div v-if="is_dispatch" class="create-new-song-wrapper-conten-field">
                <h2>תדירות הופעת השיר קידום <small>בדקות</small></h2>
                <el-input-number style="width:100%;"  v-model="promotion_interval" :min="10" :step="5" :max="100"/>
            </div>
          </div>
          <div class="create-new-song-wrapper-footer">
              <el-button v-if="!song && !is_dispatch" @click="handle_submit" type="success">שמירה</el-button>
              <el-button v-if="song && !is_dispatch" @click="handle_edit" type="warning">עריכה</el-button>
              <el-button v-if="is_dispatch" @click="handle_dispatch" type="danger">שיגור</el-button>
          </div>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {slide_pop_error,alert} from '../../../../Methods/Msgs'
import upload_image_storage from '../../../../Methods/use_general_storage'
import {save_song_in_db,edit_song_in_db,save_dispatch_song_in_db,get_promotion_song} from './scripts/script'

export default {
    props:['song','is_dispatch'],
    setup(props,{emit}){
        const {uploadImage,url,delete_image_by_url}  = upload_image_storage()

        const promotion_interval = ref(10)

        const selected_upload_option = ref('url')
        const is_pending = ref(false)
        const err_msg = ref('')
        const song_name = ref('')
        const song_url = ref('')
       
        const file = ref(null)

        const handle_change = (e)=>{
            const types = ['audio/mpeg']
            const selected = e.target.files[0]
            
            if(selected && types.includes(selected.type)){
                file.value = selected
            }else{
                file.value=null
                slide_pop_error('עליך להעלות קובץ בפורמט MP3ֱ!')
            }
        }
        
        const validation = ()=>{
            if(!song_name.value){
                err_msg.value = 'עליך להזין את שם השיר!'
                return false
            }
            if(selected_upload_option.value=='url' && !song_url.value){
                err_msg.value = 'עליך להזין כתובת URL!'
                 return false
            }
            if(selected_upload_option.value=='file' && !file.value){
                err_msg.value = 'עליך  להעלות קובץ MP3!'
                 return false
            }
            if(selected_upload_option.value=='url' && !song_url.value.includes('https://')){
                err_msg.value = 'עליך להזין כתובת URL תקינה!'
                 return false
            }
            return true
        }

        const handle_submit = async() => {
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_pending.value = true
                if(selected_upload_option.value == 'file'){
                    await uploadImage(file.value,`Songs/${song_name.value}`)
                    song_url.value = url.value
                }
                await save_song_in_db({
                   song_name:song_name.value,
                   song_url:song_url.value,
                   upload_type: selected_upload_option.value
                })
                is_pending.value = false
                alert('success','הצלחה',
                `השיר ${song_name.value} נשמר בהצלחה`)
                .then(()=>{
                    reset()
                })
            }
        }

        const handle_edit = async()=>{
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_pending.value = true
                if(selected_upload_option.value == 'file'){
                    await uploadImage(file.value,`Songs/${song_name.value}`)
                    song_url.value = url.value
                }
                if(song_url.value != props.song.song_url && props.song.upload_type == 'file'){
                      await delete_image_by_url(props.song.song_url)
                }
                await edit_song_in_db({
                   song_name:song_name.value,
                   song_url:song_url.value,
                   uid:props.song.uid,
                   upload_type: selected_upload_option.value
                })
                is_pending.value = false
                alert('success','הצלחה',
                'עריכה בוצעה בהצלחה')
                .then(()=>{
                    emit('finish_edit',{
                        song_name:song_name.value,
                        song_url:song_url.value,
                        uid:props.song.uid,
                        upload_type: selected_upload_option.value
                    })
                })
            }
        }

        const handle_dispatch = async()=>{
             if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_pending.value = true
                const previews_promosion_song = await get_promotion_song()
                if(previews_promosion_song && previews_promosion_song.upload_type == 'file'){
                    await delete_image_by_url(previews_promosion_song.song_url)
                }
                if(selected_upload_option.value == 'file'){
                    await uploadImage(file.value,`Songs/${song_name.value}`)
                    song_url.value = url.value
                }
                await save_dispatch_song_in_db({
                   song_name:song_name.value,
                   song_url:song_url.value,
                   upload_type: selected_upload_option.value,
                   promotion_interval:promotion_interval.value
                })
                is_pending.value = false
                alert('success','הצלחה',
                'שיגור שיר קידום בוצע בהצלחה')
                .then(()=>{
                    reset()
                })
            }
        }

        const reset = ()=>{
            song_name.value =''
            song_url.value = ''
            selected_upload_option.value = ''
        }

        if(props.song){
            song_name.value = props.song.song_name
            song_url.value = props.song.song_url 
        }

        return{
            handle_submit,
            handle_edit,
            handle_dispatch,
            handle_change,
            song_name,
            song_url,
            err_msg,
            is_pending,
            selected_upload_option,
            promotion_interval,

        }
    }
}
</script>

<style scoped>
    .create-new-song{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: flex;
        justify-content: center;
        background: rgba(0, 0, 0, 0.6);
        padding: 0 5px;
        color: #333;
    }
    .create-new-song-wrapper{
        width: 100%;
        max-width: 500px;
        height: fit-content;
        background: #fff;
        border-radius: 10px;
    }
    .create-new-song-wrapper-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .create-new-song-wrapper-content{
        width: 100%;
        height: fit-content;
    }
    .create-new-song-wrapper-conten-field{
        width: 100%;
        padding: 5px;
        margin-bottom: 5px;
    }
    .create-new-song-wrapper-footer{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }


    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 5px;
        left: 5px;
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: var(--danger);
        border-radius: 50%;
        cursor: pointer;
    }

</style>